<template>
	<div>
		<HeadCard />
		<a-card title="基本设置" class="context">
			<a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" @submit="handleSubmit">
				<a-form-item label="电子邮件">
					<a-input disabled v-decorator="['email', { rules: [{ required: true, message: 'Please input your note!' }] }]" />
				</a-form-item>
				<a-form-item label="请输入原密码">
					<a-input type="password" v-decorator="['old_password', { rules: [{ required: true, message: 'Please input your note!' }] }]" />
				</a-form-item>
				<a-form-item label="请输入新密码">
					<a-input type="password" v-decorator="['new_password', { rules: [{ required: true, message: 'Please input your note!' }] }]" />
				</a-form-item>
				<a-form-item label="请再次输入新密码">
					<a-input type="password" v-decorator="['newPasswordAgain', { rules: [{ required: true, message: 'Please input your note!' }] }]" />
				</a-form-item>

				<a-form-item :wrapper-col="{ span: 12, offset: 5 }">
					<a-button type="primary" html-type="submit" style="display: block; margin: 0 auto">提交</a-button>
				</a-form-item>
			</a-form>
		</a-card>
		<a-card title="重置所有订阅链接" class="context">
			<h3 style="text-align: center; margin: 0 auto; margin: '20px 0 20px 0'">注意，重置订阅链接，会让原先所使用的订阅链接全部被弃用,并生成一个新的订阅链接</h3>
			<a-button type="primary" style="display: block; margin: 0 auto" @click="resetSecret">重置订阅链接</a-button>
		</a-card>

		<Footer />
	</div>
</template>

<script>
import HeadCard from '../../components/HeadCard'
import { createNamespacedHelpers } from 'vuex'
import Footer from '../../components/Footer'
import api from '../../api/api'
import { message } from 'ant-design-vue'

const { mapActions, mapState } = createNamespacedHelpers('userInfo') //状态机

export default {
	components: {
		HeadCard,
		Footer
	},
	data() {
		return {
			formLayout: 'horizontal',
			form: this.$form.createForm(this, { name: 'coordinated' })
		}
	},
	mounted() {
		this.getUserInfo()
		this.setEmail()
	},
	methods: {
		...mapActions(['getUserInfo']),
		handleSubmit(e) {
			e.preventDefault()
			this.form.validateFields(async (err, values) => {
				if (!err) {
					console.log('Received values of form: ', values)
					const { old_password, new_password } = values
					const data = await api.changePassword({ old_password, new_password })
					console.log(data)
					if (!data) {
						message.success('修改成功')
						sessionStorage.token = ''
						this.$router.push('/login')
					} else if (old_password) {
						message.error('旧密码不正确')
					} else {
						message.error('新密码长度太短，最少6位')
					}
				}
			})
		},

		...mapActions(['getUserInfo']),
		setEmail() {
			this.form.setFieldsValue({
				email: this.userInfo.email
			})
		},
		async resetSecret() {
			const data = await api.resetSecret()
			if (!data) {
				this.$message.success('已重置订阅链接')
			}
		}
	},
	computed: {
		...mapState(['userInfo'])
	}
}
</script>

<style scoped lang="scss">
.context {
	width: 65%;
	margin: 20px auto;
}
</style>
